import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {DashboardComponent} from '@wallex-core/features/dashboard/dashboard/dashboard.component';
import {PersonalInfoGuard} from '@wallex-core/guards/personal-info.guard';
import {RoutePaths} from '@wallex-core/constants/routes.enum';
import {AuthGuard} from '@wallex-core/guards/auth.guard';
import {TwoFaGuard} from '@wallex-core/guards/2fa.guard';

import {KycGuard} from '@wallex-core/guards/kyc.guard';

const routes: Routes = [
  {path: '', redirectTo: RoutePaths.dashboard, pathMatch: 'full'},
  {
    path: RoutePaths.auth,
    loadChildren: () => import('@wallex-core/features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: RoutePaths.dashboard,
    canActivate: [AuthGuard, PersonalInfoGuard],
    component: DashboardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        canDeactivate: [KycGuard, TwoFaGuard],

        loadChildren: () => import('@wallex-core/features/main-screen/main-screen.module').then(m => m.MainScreenModule)
      },
      {
        path: `${RoutePaths.news}`,
        loadChildren: () => import('@wallex-core/features/news/news.module').then(m => m.NewsModule)
      },
      {
        path: `${RoutePaths.exchange}`,
        loadChildren: () => import('@wallex-core/features/exchange/exchange.module').then(m => m.ExchangeModule),
        canActivate: [KycGuard]
      },
      {
        path: `${RoutePaths.markets}`,
        loadChildren: () => import('@wallex-core/features/markets/markets.module').then(m => m.MarketsModule)
      },
      {
        path: `${RoutePaths.all_accounts}`,
        canDeactivate: [KycGuard, TwoFaGuard],
        loadChildren: () => import('@wallex-core/features/all-accounts/all-accounts.module').then(m => m.AllAccountsModule)
      },
      {
        path: `${RoutePaths.settings}`,
        loadChildren: () => import('@wallex-core/features/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: `${RoutePaths.savings}`,
        loadChildren: () => import('@wallex-core/features/savings/savings.module').then(m => m.SavingsModule)
      },
      {
        path: `${RoutePaths.account}/:asset`,
        loadChildren: () => import('@wallex-core/features/account/account.module').then(m => m.AccountModule)
      },
      {
        path: `${RoutePaths.notifications}`,
        loadChildren: () => import('@wallex-core/features/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: `${RoutePaths.buyCrypto}`,
        loadChildren: () => import('@wallex-core/features/buy-crypto/buy-crypto.module').then(m => m.BuyCryptoModule)
      },
      {
        path: `${RoutePaths.credits}`,
        loadChildren: () => import('@wallex-core/features/credits/credits.module').then(m => m.CreditsModule)
      }
    ]
  },
  {path: '**', redirectTo: RoutePaths.dashboard}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  providers: [AuthGuard, KycGuard, TwoFaGuard, PersonalInfoGuard]
})
export class AppRoutingModule {}
