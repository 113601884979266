import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';

export const environment: IEnvironment = {
  production: true,
  serverBaseUri: 'https://pro.wallexcustody.com',
  wsUrl: 'https://pro.wallexcustody.com',
  intercomId: 'hkgmx1f6',
  reduxStoreFreeze: false,
  reduxDevTools: false,
  reCaptchaSiteKey: '6LeIG44oAAAAAInJAft5mJsjrY40_GS4CNIrP7f9',
  isReferralProgramEnabled: true,
  appLink: 'https://app.pro.wallexcustody.com/',
  isAuthBanner: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBvApbkuwBvu50dpfH8iGiiRwUQowq03zI',
    authDomain: 'neobanking-wallex.firebaseapp.com',
    projectId: 'neobanking-wallex',
    storageBucket: 'neobanking-wallex.appspot.com',
    messagingSenderId: '913232021732',
    appId: '1:913232021732:web:c4bd036de852f5d49f44f1',
    measurementId: 'G-89S8E1NJJ1'
  },
  vapidKey: 'BJ_cIVpePs5IijyYGQcpITfGXrvgGf5HqLraG2UOLZh__QVM0773kdb4DOm7uBArRbMyH78zrlh0K1kU8P3zhiA',
  backgroundNotificationChannel: 'background-notification-channel-custody-pro',
  backgroundNotificationClickChannel: 'background-notification-click-channel-custody-pro',
  sentryDsn: 'https://ba277c6f0517fd45658cc48871e7a3a1@sentry.agilie.com/5'
};
